import React from 'react';
import { Link } from 'gatsby';

import { Container } from '../../shared/Container';

import styles from './about.module.css';

export function About({ data: { html, frontmatter: { title } } }) {
  return (
    <div className={`py-10 md:py-32 md:-mt-20 ${styles.about}`}>
      <Container className="flex justify-center">
        <div className="md:ml-64 lg:ml-35r xl:ml-40r bg-main text-white md:max-w-lg p-5">
          <h2 className="text-3xl mb-5 font-serif">{title}</h2>
          <div dangerouslySetInnerHTML={{ __html: html }} />
          <div className="text-right">
            <Link className="underline mt-5 text-right hover:no-underline" to="/research">
              Read more…
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
}
