import React from 'react'
import { graphql } from 'gatsby';

import { Layout } from '../components/shared/Layout';
import { Welcome } from '../components/home/Welcome';
import { About } from '../components/home/About';
import { Specializations } from '../components/home/Specializations'
import { Book } from '../components/home/Book';
import { Testimonials } from '../components/home/Testimonials';
import { Contact } from '../components/home/Contact';
import { SEO } from '../components/seo'

import { dataWithImages, getOptimisedImage } from '../utils/images'


export default function IndexPage({ data, location }) {
  const {
    about,
    bookPageBackgroundImage,
    contact,
    introduction,
    specializations,
    testimonials,
    images
  } = data

  return (
    <Layout location={location}>
      <SEO />
      <Welcome
        data={
          dataWithImages(introduction, getOptimisedImage(introduction.frontmatter.photo, images))
        }
      />
      <About data={about} />
      <Specializations data={specializations} />
      <Book
        data={{
          backgroundImage: bookPageBackgroundImage,
          email: contact.frontmatter.email,
          phone: contact.frontmatter.phone
        }}
      />
      <Testimonials data={testimonials} />
      <Contact data={contact} />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    images: allImageSharp {
      nodes {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
          originalName
        }
      }
    }
    bookPageBackgroundImage: imageSharp(fluid: { originalName: { eq: "flower-bg.jpg" } }) {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid_withWebp
      }
      fixed(width: 1000) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
    about: markdownRemark(frontmatter: { pid: { eq: "home-about" }}) {
      frontmatter {
        title
      }
      html
    },
    introduction: markdownRemark(frontmatter: { pid: { eq: "home-introduction" }}) {
      frontmatter {
        name
        title
        photo
      }
    }
    specializations: markdownRemark(frontmatter: { pid: { eq: "home-specialisations" }}) {
      frontmatter {
        specialisations {
          image
          specialisation
        }
      }
    }
    testimonials: markdownRemark(frontmatter: { pid: { eq: "home-testimonials" }}) {
      frontmatter {
        testimonials {
          author
          quote
        }
      }
    },
    contact: markdownRemark(frontmatter: { pid: { eq: "home-contact" }}) {
      frontmatter {
        phone
        email
        locations {
          address
          availability
          center
          location
          mapURL
          pin
          url
        }
      }
    }
  }
`;
