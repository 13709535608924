import React from 'react';
import { Parallax, Background } from 'react-parallax';
import BackgroundImage from 'gatsby-background-image'

import backgroundImageFallback from '../../../images/flower-bg.jpg';

import styles from './book.module.css';

export function Book({ data: { backgroundImage, email, phone }}) {
  return (
    <Parallax bgImage={!backgroundImage && backgroundImageFallback} strength={500}>
      {
        backgroundImage
          ? (
              <Background>
                <BackgroundImage fixed={backgroundImage.fixed} className={styles.backgroundImage} />
              </Background>
            )
          : null
      }
      <div className={`md:h-35r flex justify-center items-center ${styles.container}`} id="book">
        <div className="bg-main sm:bg-transparent p-10 text-white flex flex-col justify-center items-center">
          <div className="text-lg flex-grow-0">Email {email}</div>
          <div className="flex items-center my-10 w-full">
            <span className="h-1 mr-2 w-full bg-white" />
            <span className="text-md">OR</span>
            <span className="h-1 ml-2 w-full bg-white" />
          </div>
          <div className="text-lg flex-grow-0">call {phone}</div>
        </div>
      </div>
    </Parallax>
  )
}
