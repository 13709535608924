import { useEffect, useState } from 'react';

export function useMediaQuery(breakpoint) {
  const [matchesMediaQuery, setMatchesMediaQuery] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`);

    mediaQuery.addListener(() => setMatchesMediaQuery(mediaQuery.matches))
    setMatchesMediaQuery(mediaQuery.matches)
    return () => {
      mediaQuery.removeListener(() => setMatchesMediaQuery(mediaQuery.matches))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return matchesMediaQuery;
}
