import React from 'react';

import { Container } from '../../shared/Container'

export function Specializations({ data: { frontmatter: { specialisations } } }) {
  const background = image => `center 0 / 8rem url(${image}) no-repeat`;

  return (
    <Container className="text-gray-800 sm:pb-20 px-5 md:px-0 mt-20">
      <div className="flex items-center justify-center mb-10">
        <span className="hidden md:inline-block sm:h-1 mr-2 mt-1 w-20 bg-secondary" />
        <h2 className="text-3xl md:text-4xl py-6 text-center">Specialisations</h2>
        <span className="hidden md:inline-block sm:h-1 ml-2 mt-1 w-full bg-secondary" />
      </div>
      <div className="flex flex-col md:flex-row -mx-2 flex-wrap">
        {specialisations.map(({ specialisation, image }, key) => (
          <div className="px-2 md:w-1/3 mb-4 md:mb-0 text-center" key={key}>
            <div
              className="h-64 flex flex-col justify-end rounded py-2 mt-4"
              style={{ background: background(image) }}
            >
              <span className="text-xl h-24 flex-shrink-0">{specialisation}</span>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
}
