/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { Container } from '../../shared/Container';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

import styles from './contact.module.css';

export function Contact({ data: { frontmatter: { email, locations, phone }}}) {
  const [activeLocation, setActiveLocation] = useState(0);
  const isActive = locationNum => locationNum === activeLocation;
  const isMobile = useMediaQuery('1023');
  const currentLocation = locations[activeLocation];

  return (
    <div className="bg-secondary w-full pt-10 pb-16 px-5 md:px-0" id="contact" tab-index="-1">
      <Container className="text-gray-800">
        <h2 className="text-4xl mb-5">Contact</h2>
        <div className="flex flex-col lg:flex-row">
          <div className="border-main pt-5">
            <span className={`mb-2 ${styles.contactDetailsPhone}`}>{phone}</span>
            <span className={`${styles.contactDetailsEmail}`}>{email}</span>
          </div>
          <div className={`mt-5 lg:mt-0 lg:ml-10 xl:ml-20 flex justify-between lg:flex-col flex-wrap lg:flex-shrink-0 ${styles.locationListItem}`}>
            {locations.map((location, key) => {
              const activeDesktop = isActive(key) && !isMobile
              return (
                <div
                  className={`w-full sm:w-1/2 lg:w-auto py-5 lg:p-5 border-t-4 border-main lg:border-t-0 cursor-default break-words ${activeDesktop ? 'bg-main text-white' : ''}`}
                  key={key}
                  onClick={() => !isMobile && setActiveLocation(key)}
                >
                  <span
                    className={`mb-2 ${styles.contactDetailsAddress} ${activeDesktop ? styles.isActive : ''}`}>
                      {location.location}
                  </span>
                  <div className="pl-8 mb-3 lg:hidden">
                    <a href={location.mapURL} className="inline-block underline mb-2 -mt-1">
                      {location.address}
                    </a>
                    <a href={location.url} className="underline">{location.url}</a>
                  </div>
                  <span
                    className={`${styles.contactDetailsDates} ${activeDesktop ? styles.isActive : ''}`}>
                      {location.availability}
                  </span>
                </div>
              )
            })}
          </div>
          {!isMobile
            ? (
                <div className="p-5 bg-main text-white">
                  <div className="mb-5 flex flex-wrap justify-between">
                    <a href={currentLocation.url} className="underline">
                      {currentLocation.url}
                    </a>
                    <span>{currentLocation.address}</span>
                  </div>
                  <a href={currentLocation.mapURL}>
                    <img
                      alt="Google Maps location"
                      src={`https://maps.googleapis.com/maps/api/staticmap?center=${currentLocation.center}&zoom=16&size=600x300&maptype=roadmap&markers=color:red|${currentLocation.pin}&key=AIzaSyBYRzpJWEbGzZxzvdp1Y9y0NQnbMRiWHZs`}
                    />
                  </a>
                </div>
            )
            : ''
          }
        </div>
      </Container>
    </div>
  )
}
