import React from 'react';

import { Container } from '../../shared/Container';

import styles from './testimonials.module.css';


export function Testimonials({ data: { frontmatter: { testimonials } } }) {
  return (
    <Container className="pb-20 px-5 md:px-0 flex mt-32">
      <div className="flex flex-wrap -mx-2">
        {testimonials.map(({ author, quote }, key) => (
          <blockquote key={author} className={`w-full md:w-1/3 border-t-8 border-main p-12 ${key === 1 ? 'border-secondary md:-mt-12 md:shadow-md': 'border-main'} ${styles.testimonial}`}>
            {quote}
            <span className="block text-right mt-2">— {author}</span>
          </blockquote>
        ))}
      </div>
    </Container>
  );
}
