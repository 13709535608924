import React from 'react';
import Img from 'gatsby-image'

import { Container } from '../../shared/Container';

import styles from './welcome.module.css'

export function Welcome({ data: { frontmatter: { name, photo, title }, optimisedImage } }) {
  return (
    <Container className="flex flex-wrap md:flex-no-wrap text-gray-800 justify-around align-start px-5 md:px-0 mt-20">
      {
        optimisedImage
        ? (
            <Img
              fluid={optimisedImage.fluid}
              alt="Dr. Zeina Haoula"
              className={styles.welcomePhoto}
            />
          )
        : <img src={photo} alt="Dr. Zeina Haoula" className={styles.welcomePhoto} />
      }
      <div className="text-center mb-5 lg:mt-12 xl:mt-20">
        <h1 className="text-4xl md:text-5xl lg:text-6xl">{name}</h1>
        <div className="text-xl border-b-4 border-secondary pb-8">{title}</div>
      </div>
    </Container>
  )
}
